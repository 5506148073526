import React, { useState } from "react"
import Img from "gatsby-image"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"

import styles from "./lightbox.module.css"

const Lightbox = ({ screenshots }) => {
  const [showLightbox, setLightbox] = useState(false)
  const [selectedImage, setSelected] = useState(null)

  return (
    <div className={styles.container}>
      {screenshots.map(s => (
        <button
          key={s.node.childImageSharp.fluid.src}
          type="button"
          className={styles.showLightbox}
          onClick={() => {
            setLightbox(true)
            setSelected(s)
          }}
        >
          <Img
            className={styles.thumb}
            key={s.node.childImageSharp.fluid.src}
            fluid={s.node.childImageSharp.fluid}
          />
        </button>
      ))}

      {showLightbox && (
        <Dialog aria-label="View screenshot" allowPinchZoom={true}>
          <Img
            fluid={selectedImage.node.childImageSharp.fluid}
            className={styles.image}
          />
          <div className={styles.buttons}>
            <a
              className={`btn btn-default ${styles.dialogBtn}`}
              onClick={() => {
                const index = screenshots.findIndex(e => e === selectedImage)
                const next = index + 1
                const newIndex = next % screenshots.length
                return setSelected(screenshots[newIndex])
              }}
            >
              Next >>
            </a>
            <a
              className={`btn btn-default ${styles.dialogBtn}`}
              onClick={() => setLightbox(false)}
            >
              Close
            </a>
          </div>
        </Dialog>
      )}
    </div>
  )
}

export default Lightbox
