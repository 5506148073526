import React from "react"
import { graphql } from "gatsby"
import useScript from "../../hooks/useScript"

import Layout from "../../components/Layout/Layout"
import Lightbox from "../../components/Lightbox/Lightbox"
import SEO from "../../components/seo"
import BuyBox from "../../components/BuyBox/BuyBox"
import { Link } from "gatsby"
// import Share from "../../components/Share/Share"

import styles from "./theme.module.css"

const Theme = ({ data, pageContext, location }) => {
  // eslint-disable-next-line no-unused-vars
  const [paddleLoaded, paddleError] = useScript(
    "https://cdn.paddle.com/paddle/paddle.js"
  )
  if (paddleLoaded) {
    window.Paddle.Setup({ vendor: 111779 })
  }

  const headerAttrs = {
    title: "Modern Bundle",
    description:
      "The Modern Login and Modern Account Keycloak themes together in one package.",
  }

  return (
    <Layout location={location} headerAttrs={headerAttrs} size="lg">
      <SEO
        title={headerAttrs.title}
        description={headerAttrs.description}
        keywords={","}
      />

      <img
        src={data.logo.childImageSharp.fluid.originalImg}
        alt={
          "Modern Bundle contains the Modern Login Keycloak theme and the Modern Account Keycloak theme."
        }
        className={styles.banner}
      />
      <BuyBox paddleLoaded={paddleLoaded} productId={746827}>
        <span className={styles.priceLabel}>
          Buy Modern Bundle now for only{" "}
        </span>
        <span className={styles.priceAmount}>
          <span className={styles.crossed}>€99</span> €89 + VAT
        </span>
      </BuyBox>

      <h2>Features</h2>
      <p>
        <strong>Modern Bundle</strong> is a package that contains both a{" "}
        <Link to={`/faq#theme-types`}>login theme</Link> and an{" "}
        <Link to={`/faq#theme-types`}>account console theme</Link> for Keycloak.
      </p>
      <p>
        With <strong>Modern Bundle</strong> you'll be able to style the session
        pages, where the user signs into your domain, and the account pages,
        where the user can change its password or its personal details.
      </p>
      <p>
        Both themes are <span className={styles.bold}>responsive</span> and{" "}
        <span className={styles.bold}>tested on multiple devices</span> of
        different screen size.
      </p>
      <p>
        Both themes are also <span className={styles.bold}>customizable</span>,
        following the instructions in the included documentation you can change
        the themes logo, the primary color and the themes footer to suit your
        needs.
      </p>
      <p>
        Purchasing the themes bundle you get a 14 days money back guarantee and
        six months of <Link to={`/faq#basic-support`}>basic support</Link>.
      </p>

      <h2>Why should I buy?</h2>
      <p>
        <span className={styles.bold}>
          Building a Keycloak theme is complex
        </span>
        . Many pages are not visibile unless you setup Keycloak in a specific
        way and enable them. To develop a theme you would need to disable the
        theme cache in Keycloak and learn how to configure a new theme, how
        assets need to be declared, how FreeMarker templates works.
      </p>
      <p>
        With <strong>Modern Bundle</strong> you get two high quality themes in
        minutes, this way you can leave the complexity behind and focus on more
        important things.
      </p>

      <h2>Which pages does it includes?</h2>
      <p>
        <strong>Modern Bundle</strong> includes two themes, the{" "}
        <strong>Login theme</strong> and the{" "}
        <strong>Account Console theme</strong>. With these themes you will
        change the appearance of the follwing login-related pages:
      </p>

      <ul>
        <li>Sign in page with social logins</li>
        <li>Registration page</li>
        <li>Password recovery page</li>
        <li>Update password page</li>
        <li>Email verification page</li>
        <li>All OTP-related pages</li>
        <li>All webauhtn pages</li>
        <li>Terms and conditions page</li>
      </ul>
      <p>And the following account related pages:</p>
      <ul>
        <li>Account console dashboard</li>
        <li>Personal info page</li>
        <li>Signing in page</li>
        <li>Device Activity page</li>
        <li>Linked accounts page</li>
        <li>Applications page</li>
      </ul>

      <h2>Screenshots</h2>
      <Lightbox screenshots={data.screenshots.edges} />

      <h2>What do you get</h2>
      <p>
        By purchasing <strong>Modern Bundle</strong> you get:
      </p>
      <ul>
        <li>A package ready to be deployed on your Keycloak installation</li>
        <li>Documentation about the themes customization</li>
        <li>Documentation about the themes installation</li>
        <li>Six months of basic support</li>
      </ul>

      <BuyBox paddleLoaded={paddleLoaded} productId={746827}>
        <span className={styles.priceLabel}>
          Buy Modern Bundle now for only{" "}
        </span>
        <span className={styles.priceAmount}>
          <span className={styles.crossed}>€99</span> €89 + VAT
        </span>
      </BuyBox>
    </Layout>
  )
}

export default Theme

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        siteUrl
        social {
          twitter
        }
      }
    }
    logo: file(absolutePath: { regex: "/modern_bundle_banner_alt.jpg/" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    screenshots: allFile(
      filter: {
        name: { regex: "/^mb/" }
        sourceInstanceName: { eq: "screenshots" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
