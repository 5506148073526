import React from "react"

import styles from "./buybox.module.css"

const BuyBox = ({ paddleLoaded, background, children, productId }) => {
  return (
    <div className={`${styles.bordered}`}>
      <div className={styles.price}>{children}</div>
      {false && paddleLoaded && (
        <a
          href="#!"
          className="btn btn-lg"
          onClick={() => {
            window.Paddle.Checkout.open({ product: productId })
          }}
        >
          Buy Now!
        </a>
      )}
    </div>
  )
}
export default BuyBox
